import { CircularProgress } from '@mui/material';

const MyLoader = ({size,color,margin}) => {
  return (
    <div style={{textAlign:'center', display:'flex', justifyContent:'center'}}>
      <CircularProgress size={size ? size : 22} style={{margin:margin ? margin : '',color:`${ color ? color : '#E2F8FF'}`  }}/>    
    </div>
  )
}

export default MyLoader