
import G1798 from '../assets/Captchas/G1798.png';
import G2421 from '../assets/Captchas/G2421.png';
import G3812 from '../assets/Captchas/G3812.png';
import G5621 from '../assets/Captchas/G5621.png';
import G8554 from '../assets/Captchas/G8554.png';
import G6984 from '../assets/Captchas/G6984.png';


const captchas = [
    { image: G1798, value: "1798" },
    { image: G2421, value: "2421" },
    { image: G3812, value: "3812" },
    { image: G5621, value: "5621" },
    { image: G8554, value: "8554" },
    { image: G6984, value: "6984" }

];

export default captchas;