import React from "react";

const UserContext = React.createContext({
  user: null,
  setUser: () => { },
  userInfo: null,
  setUserInfo: () => { },
  wheelInfo: null,
  setWheelInfo: () => { },
  // dagaID: null,
  // setDagaID: () => {},
  dagaBalance: null,
  setDagaBalance: () => { },
  withdrawamount: null,
  setWithdrawAmount: () => { },
  userbankdetails: null,
  setUserbankdetails: () => { },
  gamesRates: null, 
  setGamesRates: () => {},
});

export default UserContext;