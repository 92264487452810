import axios from "axios";

const BaseUrl = axios.create({
  // Staging gasv388 Test
  // baseURL: "https://staging.gasv388.net/api",

  // Master testing
  // baseURL: "https://master-gasv.iegaming.io/api",

  // gasv388 base URL Live
  baseURL: "https://0293ie0w0e8.com/api",



});

export default BaseUrl;