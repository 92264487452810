import './App.css';
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Login from './Pages/login/Login';
import Register from './Pages/register/Register';
import { BrowserRouter as Router } from 'react-router-dom';
import Playnow from './Pages/Playnow/Playnow';

function App() {
  // Auth routes
  const AuthRoute = ({ children }) => {
    if (!localStorage.getItem("redirect_link")) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };
  return (
    <div className="App"> 
      <Router>
      <Routes>
        <Route path="/" element={<AuthRoute><Playnow /></AuthRoute>} />
        <Route path="/login" element={ <Login /> } />
        <Route path="/register" element={ <Register /> } />
      </Routes>
      </Router>

    </div>
  );
}

export default App;
